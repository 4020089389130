import React from 'react';

function LibraryPage() {
    return (
        <div>
            <h2>Stubbed Page for my Library</h2>
            <p>Upload some crucial files I have stored locally:</p>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Public domain books</li>
                <li>Public domain music</li>
                <li>Public domain software/code</li>
            </ul>
        </div>
    );
}

export default LibraryPage;