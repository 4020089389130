import React from 'react';

function StubPage({ input }) {
    return (
        <div>
            <h2>Stubbed Page for {input}</h2>

            I will write something here, or reupload previous content
        </div>
    );
}

export default StubPage;