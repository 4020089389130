import React from 'react';

function EvaPage() {
    return (
        <div>
            <h2>Eva</h2>
            <p>Born March 9, 2024 at 6:53pm EDT.</p>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>First Song: Bach Cello Suite by Yo-Yo Ma</li>
                <li>First non-Classical Song: Moon in June by Robert Wyatt</li>
                <li>First Poem: Sailing to Byzantium by William Butler Yates</li>
                <li>First Non-Baby Food: Watermelon</li>
                <li>First Dessert: Pumpkin Bread (stolen)</li>
            </ul>
        </div>
    );
}


export default EvaPage;