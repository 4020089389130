// import React, { useState, useEffect } from 'react';
// import './LCPage.css';
//
// function LCPage() {
//     const [selectedAnswer, setSelectedAnswer] = useState(null);
//
//     const handleAnswerClick = (answer, event) => {
//         event.stopPropagation(); // Prevent the click from bubbling up to the document
//         setSelectedAnswer(answer);
//     };
//
//     useEffect(() => {
//         // Function to handle click outside the answers
//         const handleClickOutside = () => {
//             setSelectedAnswer(null);
//         };
//
//         // Add event listener to the document
//         document.addEventListener('click', handleClickOutside);
//
//         // Cleanup function
//         return () => {
//             document.removeEventListener('click', handleClickOutside);
//         };
//     }, []);
//
//     return (
//         <div>
//             <h2>How do you solve the two-sum problem?</h2>
//             <div className="answers">
//                 <div className={`answer ${selectedAnswer === 'incorrect1' ? 'selected incorrect1' : ''}`} onClick={(e) => handleAnswerClick('incorrect1', e)}>
//                     You should sort the array first and then use a binary search for each element to find its complement to reach the target sum.
//                 </div>
//                 <div className={`answer ${selectedAnswer === 'correct' ? 'selected correct' : ''}`} onClick={(e) => handleAnswerClick('correct', e)}>
//                     To solve the two-sum problem, create a hash table to store each number's index as you iterate through the array, and for each element, check if the target number minus the current element is already in the hash table. If it is, you've found the two numbers that add up to the target; if not, add the current element's index to the hash table.
//                 </div>
//                 <div className={`answer ${selectedAnswer === 'incorrect2' ? 'selected incorrect2' : ''}`} onClick={(e) => handleAnswerClick('incorrect2', e)}>
//                     Loop through the array with two nested loops, adding every possible pair of numbers to find the ones that sum up to the target value.
//                 </div>
//             </div>
//         </div>
//     );
// }
//
// export default LCPage;

import React, { useState, useEffect } from 'react';
import './LCPage.css';
import questionsData from '../assets/neetcode150.json'; // Assuming your JSON is named 'questions.json'

function LCPage() {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [shuffledAnswers, setShuffledAnswers] = useState([]);

    const handleAnswerClick = (answer, event) => {
        event.stopPropagation();
        setSelectedAnswer(answer);
    };

    const shuffleAnswers = (question) => {
        const answers = Object.entries(question).filter(([key]) => key !== 'question');
        for (let i = answers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [answers[i], answers[j]] = [answers[j], answers[i]];
        }
        return answers;
    };

    const loadRandomQuestion = () => {
        const randomIndex = Math.floor(Math.random() * questionsData.length);
        const question = questionsData[randomIndex];
        setSelectedAnswer(null); // Reset selected answer
        setCurrentQuestion(question);
        setShuffledAnswers(shuffleAnswers(question));
    };

    useEffect(() => {
        loadRandomQuestion(); // Load the first question on mount

        const handleClickOutside = () => {
            setSelectedAnswer(null);
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    if (!currentQuestion) {
        return <div>Loading...</div>; // Or any other loading state
    }

    return (
        <div>
            <h2>{currentQuestion.question}</h2>
            <div className="answers">
                {shuffledAnswers.map(([key, value]) => (
                    <div
                        key={key}
                        className={`answer ${selectedAnswer === key ? 'selected ' + key : ''}`}
                        onClick={(e) => handleAnswerClick(key, e)}
                    >
                        {value}
                    </div>
                ))}
            </div>
            <button onClick={loadRandomQuestion}>Next</button>
        </div>
    );
}

export default LCPage;