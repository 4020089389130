import styled from 'styled-components';

export const Title = styled.div` display: flex; padding: 1em 1em 0 1em; font-size: 2em`;

export const Row = styled.div` display: flex; padding: 1em`;

export const Col = styled.div` 
  flex: ${(props) => props.size}; 
  text-align: ${(props) => props.textAlign};
  flex-wrap: ${(props) => props.flexWrap};
`;
