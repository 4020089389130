import './App.css';
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Row, Col, Title} from "./Grid";
import aboutme from './assets/aboutme.png';
import LCPage from './pages/LCPage';
import StubPage from "./pages/StubPage";
import LibraryPage from "./pages/LibraryPage";
import EvaPage from "./pages/EvaPage";

const App = () => {
  return (
      <div className="App">
          <Router>
              <Routes>
                  <Route path="/" element={
                      <>
                          <Title>
                              <Col size={1}> Kyle Buday</Col>
                          </Title>
                          <Row className="row">
                              <Col size={3} className="col" collapse={"xs"}> </Col>

                              <Col size={5} className="col">
                                  <Row className="row">
                                      {/*<Col size={1} className="col"><img src={logo} className="App-logo" alt="logo" /> </Col>*/}
                                      <Col size={4} className="col" textAlign={'left'} flexWrap={'wrap'}> I am a Boston-based software developer who has been professionally programming for 9 years.
                                      </Col>
                                  </Row>
                                  <Row className="row">
                                      <Col size={4} className="col" textAlign={'left'}>
                                          Contact me: <a href={'https://www.linkedin.com/in/kylebuday'}>Linkedin</a>, <a href={'https://www.facebook.com/kyle.buday.9'}>Facebook</a>, <a href={'https://github.com/budayk'}>Github</a>, or kyle.g.buday@gmail.com.
                                      </Col>
                                  </Row>
                              </Col>
                              <Col size={5} className="col">
                                  <img src={aboutme} className="responsive-img" alt="About me" />
                              </Col>
                              <Col size={3} className="col" collapse={"xs"}> </Col>
                          </Row>
                      </>
                  } />
                  <Route path="/lc" element={< LCPage />} />
                  <Route path="/ev" element={<EvaPage />} />
                  <Route path="/dr" element={<StubPage input="Destini" />} />
                  <Route path="/ab" element={<StubPage input="AB Testing" />} />
                  <Route path="/mu" element={<StubPage input="Music" />} />
                  <Route path="/ne" element={<StubPage input="New England" />} />
                  <Route path="/ro" element={<StubPage input="Robots" />} />

                  {/* A bunch of pseudo redirects to my other sites route in case I forget */}
                  <Route path="/ww" element={<StubPage input="Other sites" />} />
                  <Route path="/www" element={<StubPage input="Other sites" />} />

                  {/* A bunch of pseudo redirects to my library route in case I forget */}
                  <Route path="/lib" element={<LibraryPage />} />
                  <Route path="/med" element={<LibraryPage />} />
                  <Route path="/dir" element={<LibraryPage />} />

                  {/* Add more routes as needed */}
              </Routes>
          </Router>
      </div>
  );
}
export default App;
